import { useState } from 'react';
import CloudIcon from '@mui/icons-material/Cloud';

import Spotify from './components/spotify/Spotify';
import Weather from './components/weather/Weather';
import api from './api/api';

import clear from './images/clear.gif';
import cloud from './images/cloud.gif';
import rain from './images/rain.gif';
import snow from './images/snow.gif';
import mist from './images/mist.gif';

import './App.css';

const App = () => {
    const [backgroundImg, setBackgroundImg] = useState<any>();
    const [token, setToken] = useState('');
    const [url, setUrl] = useState('');

    const setWeatherImg = (weather: string) => {
        let img = '';
        let playlist = '';

        switch(weather) {
            case 'Clouds':
                img = cloud;
                playlist = 'cloudy day';
                break;
            case 'Rain':
                img = rain;
                playlist = 'rainy day';
                break;
            case 'Snow':
                img = snow;
                playlist = 'snowy day';
                break;
            case 'Mist':
                img = mist;
                playlist = 'misty day';
                break;
            case 'Sun':
            default:
                img = clear;
                playlist = 'sunny day';
        }

        setBackgroundImg(img);
        getPlaylist(playlist);
    }

    const setUserToken = (token: string) => {
        setToken(token);
    }

    const getPlaylist = (searchTerm: string) => {
        api.getPlaylist(searchTerm, token)
            .then(data => {
                const numberOfPlaylists = data.playlists.items.length;
                const randomIndex = Math.floor(Math.random() * numberOfPlaylists)
                let externalUrl = data.playlists.items[randomIndex].external_urls.spotify;

                if(externalUrl) {
                    const formattedUrl = externalUrl.replace('/playlist/', '/embed/playlist/');
                    setUrl(`${formattedUrl}${'?utm_source=generator'}`);
                }
            })
            .catch(err => {
                console.error("error", err)
            })
    }

    return (
        <div className='app' style={{ backgroundImage: `url(${backgroundImg})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}>
            <div className='container'>
                <h1 className='main-header'>
                    Weather<span className='main-header-accent'>fy</span>
                </h1>
                <Spotify setUserToken={setUserToken} />
                {token && <Weather setWeatherImg={setWeatherImg} />}
                {url &&
                    <iframe
                        className='iframe'
                        src={url}
                        frameBorder="0"
                        allowFullScreen
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy">
                    </iframe>
                }
                {token && !url &&
                    <div className='empty-frame'>
                        <div><CloudIcon className='cloud-icon' /></div>
                        <p>It's quiet in here...</p>
                    </div>
                }
                <div className='footer'>Made by <a href='https://tyypos.com' target='_blank' className='link'>Tyypos</a></div>
            </div>
        </div>
    );
}

export default App;
