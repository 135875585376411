const api = {
    getWeatherByZipcode(zipCode) {
        return fetch(`https://api.openweathermap.org/data/2.5/weather?zip=${zipCode}&units=imperial&appid=${process.env.REACT_APP_WEATHER_KEY}`)
            .then(res => {
                return res.json();
            })
            .catch(err => {
                return err;
            })
    },
    getWeatherByLatLon(lat, lon) {
        return fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=imperial&appid=${process.env.REACT_APP_WEATHER_KEY}`)
            .then(res => {
                return res.json();
            })
            .catch(err => {
                return err;
            })
    },
    getPlaylist(searchTerm, token) {
        return fetch(`https://api.spotify.com/v1/search?q=${searchTerm}&type=playlist&limit=10`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => {
                return res.json();
            })
            .catch(err => {
                return err;
            })
    }
}

export default api;