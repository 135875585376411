import { useEffect, useState } from "react";
import { Button, TextField, CircularProgress } from '@mui/material';

import api from '../../api/api.js';

import './Weather.css';

const Weather = (props: any) => {
    const {setWeatherImg} = props;
    const [zipcode, setZipcode] = useState('');
    const [weather, setWeather] = useState<any>();
    const [isLookingForLocation, setIsLookingForLocation] = useState(true);

    const getWeatherByZipcode = () => {
        api.getWeatherByZipcode(zipcode)
            .then(data => {
                setWeatherImg(data.weather[0].main)
                setWeather(data);
            })
            .catch(err => {
                console.error("error", err)
            })
    }

    const onKeyDown = (e: any) => {
        // pressed enter and the zipcode is valid length
        if(e.keyCode == 13 && zipcode.length === 5){
            getWeatherByZipcode();
         }
    }

    useEffect(() => {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((pos) => {
                const lat = pos.coords.latitude;
                const lon = pos.coords.longitude;
                api.getWeatherByLatLon(lat, lon)
                    .then(data => {
                        setWeatherImg(data.weather[0].main)
                        setWeather(data);
                        setIsLookingForLocation(false);
                    })
                    .catch(err => {
                        console.error("error", err)
                    })
            }, (error) => {
                console.error("error", error)
                setIsLookingForLocation(false);
            })
        } else {
            console.log("location not enabled");
            setIsLookingForLocation(false);
        }
    }, [])

    return (
        <div className='weather-container'>
            {isLookingForLocation &&
                <div className='loading'>
                    <div className='loader-container'><CircularProgress size={40} /></div>
                    <div>Attempting to Weatherfy your location automatically...</div>
                </div>
            }

            {!isLookingForLocation &&
                <div>
                    <div className='zipcode-container'>
                        <h1 className='zipcode-header'>Enter a zipcode to Weatherfy</h1>
                        <div className='zipcode-controls'>
                            <div className='zipcode-field'>
                                <TextField
                                    id="zipcode"
                                    label="Zipcode"
                                    variant="outlined"
                                    value={zipcode}
                                    onChange={(e: any) => setZipcode(e.target.value)}
                                    type="number"
                                    onInput = {(e: any) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,5)
                                    }}
                                    onKeyDown={(e) => onKeyDown(e)}
                                />
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    onClick={() => getWeatherByZipcode()}
                                    disabled={zipcode.length < 5}
                                    className='btn'
                                >
                                    Weatherfy
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='weather-details-container'>
                            <div className={weather ? '' : 'hidden'}>City: <span className='weather-acccent'>{weather?.name}</span></div>
                            <div className={weather ? '' : 'hidden'}>Weather: <span className='weather-acccent'>{weather?.weather[0].description}</span></div>
                            <div className={weather ? '' : 'hidden'}>Actual temp: <span className='weather-acccent'>{weather?.main.temp && weather.main.temp + '°'}</span></div>
                            <div className={weather ? '' : 'hidden'}>Feels like: <span className='weather-acccent'>{weather?.main.feels_like && weather.main.feels_like + '°'}</span></div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Weather;