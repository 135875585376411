import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import './Spotify.css';

const authEndpoint = 'https://accounts.spotify.com/authorize';
// http://weatherfy.net/
// http://localhost:3000/
const redirectUri = 'http://weatherfy.net';
const scopes = [
    'user-read-currently-playing',
    'user-read-playback-state',
];

// Get the hash of the url
const hash = window.location.hash
    .substring(1)
    .split("&")
    .reduce(function(initial: any, item) {
        if (item) {
            var parts = item.split("=");
            initial[parts[0]] = decodeURIComponent(parts[1]);
        }

        return initial;
    }, {});

window.location.hash = "";

const Spotify = (props: any) => {
    const { setUserToken } = props;
    const [token, setToken] = useState();

    useEffect(() => {
        const _token = hash.access_token;

        if(_token) {
            setToken(_token);
            setUserToken(_token);
        }
    }, [])

    return (
        <div>
            {!token &&
                <div>
                    <h1 className='spotify-main-header'>
                        The perfect playlist for any weather.
                    </h1>
                    <h2 className='spotify-sub-header'>
                        Weatherfy uses location data to look up the weather
                        in the area and smartly matches it to a Spotify playlist.
                    </h2>
                    <h2 className='spotify-sub-header'>
                        To get started, connect your Spotify account below.
                    </h2>
                    <div className='spotify-btn-container'>
                        <a
                            className='link'
                            href={`${authEndpoint}?client_id=${process.env.REACT_APP_SPOTIFY_KEY}&redirect_uri=${redirectUri}&scope=${scopes.join("%20")}&response_type=token&show_dialog=true`}
                        >
                            <Button variant="contained">Login to Spotify</Button>
                        </a>
                    </div>
                </div>
            }
            {token &&
                <div>
                    <p className='step-complete'>✅ Spotify account successfully connected</p>
                </div>
            }
        </div>
    );
}

export default Spotify;